@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;

    & ::selection {
        @apply bg-slate-800 text-white
    }
}

body {
    @apply font-sans font-bold;
}

h1,
h2,
h3 {
    text-wrap: balance;
}

.emoji {
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    transform: translateY(-0.1em);
}

.emoji-illustration-container .emoji {
    height: 1.2em;
    width: 1.2em;
    transform: translateX(0em) translateY(0em);
}


/* If focus visible, show focus indicator around element */
/* Use data-focus-visible="true" to show focus indicator */
[data-rac][data-focus-visible="true"],
:not([data-rac]):focus-visible {
	@apply outline outline-4 outline-cornflower-700 ring-4 ring-rose outline-offset-2 ring-offset-4
}

/* If not focus visible, set outline to 0 (the data attribute will be missing or false) */
[data-rac]:not([data-focus-visible="true"]), :not([data-rac]) {
	@apply outline-0 ring-0;
}
